<template>
    <div class="">
        <div class="row" style="background-color: #F3FDFF;">
            <div class="col-2 container">
                <img src="resources/images/medisync-big.svg">
            </div>
            <div class="col-6 header-text container">Catatan Perkembangan Pasien Terintegrasi (CPPT)</div>
            <div class="col-4">
                <div class="container">
                    <table class="table-pasien-info">
                        <tbody>
                            <tr>
                                <td>
                                    <p>Nama Pasien</p>
                                </td>
                                <td>
                                    <p>:</p>
                                </td>
                                <td>
                                    <p>{{ namaPasien }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>No. RM</p>
                                </td>
                                <td>
                                    <p>:</p>
                                </td>
                                <td>
                                    <p>{{ noRM }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Tanggal Lahir</p>
                                </td>
                                <td>
                                    <p>:</p>
                                </td>
                                <td>
                                    <p>{{ tanggalLahir }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Umur</p>
                                </td>
                                <td>
                                    <p>:</p>
                                </td>
                                <td>
                                    <p>{{ umur }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Jenis Kelamin</p>
                                </td>
                                <td>
                                    <p>:</p>
                                </td>
                                <td>
                                    <p>{{ jenisKelamin }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Jenis Jaminan</p>
                                </td>
                                <td>
                                    <p>:</p>
                                </td>
                                <td>
                                    <p>{{ jenisJaminan }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <br>
        <a-form :form="form" @submit="handleSubmit"> 
            <div class="card">
                <div class="card-header card-custom">
                    <div class="utils__title">
                        <p style="font-weight:bold; font-size:large;">Detail Pasien</p>
                        <div class="table-responsive">
                            <div class="form-container">
                                <a-row :gutter="1" > 
                                    <a-col :span="6">
                                        <a-form-item label="Tekanan Darah Sintatik" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-input v-model="tekanan_darah_sintaktik" type="text" class="input-with-text"/>
                                            <span class="input-text">mmHg</span>
                                        </a-form-item>
                                    </a-col>

                                    <a-col :span="6">
                                        <a-form-item label="Saturasi" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-input v-model="saturasi" type="text" class="input-with-text"/>
                                            <span class="input-text">%</span>
                                        </a-form-item>
                                    </a-col>
                                    
                                    <a-col :span="6">
                                        <a-form-item label="Frekuensi Nafas" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-input v-model="frekuensi_nafas" type="text" class="input-with-text"/>
                                            <span class="input-text">karmen</span>
                                        </a-form-item>
                                    </a-col>
                                    
                                    <a-col :span="6">
                                        <a-form-item label="Berat Badan" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-input v-model="berat_badan" type="text" class="input-with-text"/>
                                            <span class="input-text">kg</span>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="1">
                                    <a-col :span="6">
                                        <a-form-item label="Tekanan Darah Diastolik" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-input v-model="tekanan_darah_diastolik" type="text" class="input-with-text"/>
                                            <span class="input-text">mmHg</span>
                                        </a-form-item>

                                    </a-col>
                                    
                                    <a-col :span="6">
                                        <a-form-item label="Nadi" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-input v-model="nadi" type="text" class="input-with-text"/>
                                            <span class="input-text">karmen</span>
                                        </a-form-item>
                                    </a-col>
                                    
                                    <a-col :span="6">
                                        <a-form-item label="Tinggi Badan" :label-col="labelCol" :wrapper-col="wrapperCol" >
                                            <a-input v-model="tinggi_badan_cm" type="text" class="input-with-text"/>
                                            <span class="input-text">cm</span>
                                        </a-form-item>
                                    </a-col>
                                    
                                    <a-col :span="6">
                                        <a-form-item label="Alergi" :label-col="labelCol" :wrapper-col="wrapperCol">
                                            <a-dropdown class="dropdown">
                                                <template #overlay>
                                                    <a-menu @click="handleMenuClick">
                                                    <a-menu-item value="Tidak">Tidak</a-menu-item>
                                                    <a-menu-item value="Ya">Ya</a-menu-item>
                                                    </a-menu>
                                                </template>
                                                <a-button class="dropdown-flex">
                                                    {{ alergi }}
                                                    <a-icon type="down" />
                                                </a-button>
                                            </a-dropdown>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a-tabs>
                <a-tab-pane key="1" tab="Transkrip">
                    <div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div v-if="this.transcript==null" class="big-text">
                                        Silakan Mulai Bicara...
                                    </div>
                                    <div v-else>
                                        {{ this.transcript }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Note (SOAP)">
                    <div>
                        <div class="medium-text">Subjective</div>
                        <div class="small-text">21-year-old female named Diana presents with a chief complaint of feeling unwell. She reports having a fever and feeling terrible. She also mentions having a headache, a cold with continuous sneezing, backache, and chest pain.</div>
                        <br>
                        <div class="medium-text">Objective</div>
                        <div class="small-text">- Vitals: Blood pressure measured at 120/80 <br> - Physical exam: Patient was found to have a mild fever during the examination. No other physical examinations were reported.</div>
                        <br>
                        <div class="medium-text">Assessment & Plan</div>
                        <div class="small-text">Fever <br> - Patient reports feeling unwell and having a fever. Physical examination confirms a mild fever. <br> - Prescription of unspecified medication, to be taken 2 tablets thrice a day with or without meals. Rest is also advised after taking the medication.</div>
                    </div>
                </a-tab-pane>
            </a-tabs>

            <div class="buttons-container">
                <button v-if="!isRecording" type="button" class="button left-button" @click.prevent="startRecording">
                    <img src="resources/images/mic-logo.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                    Mulai Rekam
                </button>
                <button v-else type="button" class="button right-button" @click="stopRecording">
                    <img src="resources/images/pause-logo.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">
                    Berhenti
                </button>
                <button type="button" class="button right-button">
                    <img src="resources/images/note-icon.svg" alt="Icon" style="width: 20px; height: 20px; margin-right: 8px;">    
                    Buat note (SOAP)
                </button>
                <button type="submit" class="button left-button">
                    Simpan Data
                </button>
            </div>
        </a-form>


    </div>
</template>

<script>
import { getById } from '@/services/axios/api/pasien';
import { getByMedRecordId, update, uploadAudio } from '@/services/axios/api/medicalRecord';

const labelCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

export default {
    name: 'Formulir',
    data() {
        return {
            labelCol,
            wrapperCol,
            form: this.$form.createForm(this),
            id: null,
            idMedRec: null,
            alergi: 'Tidak',
            namaPasien: 'Nama',
            noRM: 'No. RM',
            tanggalLahir: 'Tanggal Lahir',
            umur: 'Umur',
            jenisKelamin: 'Jenis Kelamin',
            jenisJaminan: 'Asuransi',
            isRecording: false,
            recorder: null,
            transcript: null,
            audioSegments: [],
            audioChunks: [],
            tekanan_darah_sintaktik: null,
            saturasi: null,
            frekuensi_nafas: null,
            berat_badan: null,
            tekanan_darah_diastolik: null,
            nadi: null,
            tinggi_badan_cm: null,
            };
    },
    created(){
        this.idMedRec = this.$route.params.id;
    },
    async mounted(){
        const response = await getByMedRecordId(this.idMedRec);
        const medicalRecord = response.medicalRecord;
        // console.log('response:', response.medicalRecord.patient_id);
        this.id = medicalRecord.patient_id;
        this.tekanan_darah_sintaktik = medicalRecord.tekanan_darah_sintaktik;
        this.saturasi = medicalRecord.saturasi;
        this.frekuensi_nafas = medicalRecord.frekuensi_nafas;
        this.berat_badan = medicalRecord.berat_badan;
        this.tekanan_darah_diastolik = medicalRecord.tekanan_darah_diastolik;
        this.nadi = medicalRecord.nadi;
        this.tinggi_badan_cm = medicalRecord.tinggi_badan_cm;
        this.alergi = medicalRecord.alergi;
        this.transcript = medicalRecord.transcript;

        const data = await getById(this.id);
        this.namaPasien = data.patient.name;
        this.noRM = data.patient.no_rm;
        this.tanggalLahir = this.changeDateFormat(data.patient.birthdate);
        this.umur = this.calculateAge(data.patient.birthdate);
        this.jenisKelamin = data.patient.gender === "male"? 'Laki-laki' : 'Perempuan';
        this.jenisJaminan = data.patient.insurance;
    },
    methods: {
        calculateAge(birthdate) {
            const today = new Date();
            const birthDate = new Date(birthdate);

            const years = today.getFullYear() - birthDate.getFullYear();
            const months = today.getMonth() - birthDate.getMonth();
            const days = today.getDate() - birthDate.getDate();

            let ageYears = years;
            let ageMonths = months;
            let ageDays = days;

            if (ageDays < 0) {
                const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                ageDays += prevMonth.getDate();
                ageMonths -= 1;
            }

            if (ageMonths < 0) {
                ageMonths += 12;
                ageYears -= 1;
            }

            return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`;
        },

        changeDateFormat(tanggal){
            console.log('tanggal', tanggal)
            const dateObject = new Date(tanggal)
            console.log('dateObject', dateObject)
            const formattedDate = dateObject.toLocaleString('en-US', {
                hour12: false,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formattedDate
        },

        handleMenuClick({key, item}) {
            this.alergi = item.$slots.default[0].text;
        },

        async startRecording() {
            this.isRecording = true;
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.recorder = new MediaRecorder(stream);
            this.recorder.ondataavailable = event => {
                this.audioChunks.push(event.data);
                console.log('event.data:', event.data);
            };
            this.recorder.start();

            const intervalId = setInterval(() => {
                if (!this.isRecording) {
                    clearInterval(intervalId);
                } else {
                    this.recorder.stop();
                    this.recorder.start();
                    const audioBlob = new Blob(this.audioChunks, { 'type' : 'audio/mp3' });
                    this.sendAudio(audioBlob);
                    this.audioChunks.length = 0;
                }
            }, 5000); // 5 Detik
        },

        async stopRecording() {
            this.isRecording = false;
            this.recorder.stop();
            if (this.audioChunks.length > 0) {
                const audioBlob = new Blob(this.audioChunks, { 'type' : 'audio/mp3' });
                this.sendAudio(audioBlob);
            }
            this.audioSegments = [];
        },

        async sendAudio(audioBlob){
            this.audioSegments.push(audioBlob);
            console.log('Recording Segments:', this.audioSegments);
            console.log('Recording Segments Length:', this.audioSegments.length);

            if (this.audioSegments.length > 1) {
                // const url = URL.createObjectURL(audioBlob);
                // console.log('URL:', url)
                // const a = document.createElement('a');
                // a.href = url;
                // a.download = `segment-${this.audioSegments.length}.mp3`;
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);
                // URL.revokeObjectURL(url);

                const formData = new FormData();
                formData.append('model', 'tiny');
                formData.append('audiofile', audioBlob, `segment-${this.audioSegments.length}.mp3`);
                console.log('formdata', formData);
                const response = await uploadAudio(this.idMedRec, formData);
                console.log('Response Audio:', response);
                const transcript = response.data.transcript;
                if (this.transcript == null) {
                    this.transcript = transcript;
                } else {
                    this.transcript += " ";
                    this.transcript += transcript;
                }
                
            }
        },

        handleSubmit(e){
            e.preventDefault()
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    try{
                        values.alergi = this.alergi;
                        values.tekanan_darah_sintaktik = this.tekanan_darah_sintaktik;
                        values.saturasi = this.saturasi;
                        values.frekuensi_nafas = this.frekuensi_nafas;
                        values.berat_badan = this.berat_badan;
                        values.tekanan_darah_diastolik = this.tekanan_darah_diastolik;
                        values.nadi = this.nadi;
                        values.tinggi_badan_cm = this.tinggi_badan_cm;
                        values.transcript = this.transcript;
                        console.log('Received values of form: ', values)
                        const response = await update(this.idMedRec, values)
                        console.log(response)
                        if (response.status === 200){
                            this.$notification.success({
                                message: 'Sukses',
                                description: 'Data medis pasien berhasil diperbarui',
                            })
                            this.$router.push(`/rekam-medis/${this.id}`);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            })
        },
    },
}
</script>

<style scoped>
.card-custom{
    border-radius: 30px;
    border-color: black;
}
.header-text{
    font-size: 30px;
    text-align: center;
    color: #428A94;
    font-weight: bold;
}
.table-pasien-info p {
    margin: 5px;
    padding: 0;
    line-height: 1;
}

@media (max-width: 2000px) {
  .table-pasien-info p {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  .table-pasien-info p {
    font-size: 12px;
  }
}

@media (max-width: 950px) {
  .table-pasien-info p {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .table-pasien-info p {
    font-size: 8px;
  }
}

.big-text{
    font-size: 30px;
    text-align: left;
    color: black;
}
.medium-text{
    font-size: 25px;
    text-align: left;
    color: black;
}
.small-text{
    font-size: 15px;
    text-align: left;
    color: black;
}
p {
  color: black;
}
.table {
  border-collapse: collapse;
}

.table td, .table th {
  border: none;
  padding-bottom: 10px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
}

.dropdown {
  border: 1px solid #E2DDDD;
  width: 162px;
  height: 37px;
}

.dropdown-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Input */
input {
    border: 1px solid #E2DDDD;
    border-radius: 5px;
}

.input-container {
  position: relative;
  display: inline-block;

}

.input-with-text {
  padding-right: 0px;
  border: 1px solid #E2DDDD;
  border-radius: 5px;
  width: 162px;
  height: 37px;
}

.input-text {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: #757575;
}

/* Button */
.buttons-container {
    display: flex;
    justify-content: flex-end;

}
.buttons-container .button {
    margin: 10px;
    border-radius: 20px;
    padding: 5px 10px;
    
}
.left-button{
    background-color: #428A94;
    border: none;
    color: white;
}

.right-button{
    background-color:white;
    border: 1px solid #428A94;
    color: #428A94;
}

.form-container {
    display: flex;
    flex-direction: column;
}
</style>